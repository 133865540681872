import { Dispatch } from 'redux';
import {
  DataOfClientsType,
  DateInfoOfClientType,
  DoctorType,
  HolidayType,
  ReasonsInfoType,
  registrationAPI,
  WorkScheduleInfoType,
} from '../api/registration-api';
import { handleServerAppError, handleServerNetworkError } from '../utils/error-utils';
import { setAppStatusAC } from './app-reducer';
import { AppRootStateType } from './store';

const SET_CHOSEN_DAY_SCHEDULE = 'registrationReducer/SET_CHOSEN_DAY_SCHEDULE';
const GET_DATE_REGISTRATION = 'registrationReducer/GET_DATE_REGISTRATION';
const SET_DATA_STEP1 = 'registrationReducer/SET_DATA_STEP1';
const GET_BRANCH_INFO = 'registrationReducer/GET_BRANCH_INFO';
const SET_DESIRED_DATE = 'registrationReducer/SET_DESIRED_DATE';
const SET_SELECTED_INSURANCE = 'registrationReducer/SET_SELECTED_INSURANCE';
const SET_SELECTED_REASON = 'registrationReducer/SET_SELECTED_REASON';
const SET_SELECTED_GENDER = 'registrationReducer/SET_SELECTED_GENDER';
const SET_SELECTED_BIRTH_DATE = 'registrationReducer/SET_SELECTED_BIRTH_DATE';
const SET_SELECTED_FIRST_NAME = 'registrationReducer/SET_SELECTED_FIRST_NAME';
const SET_SELECTED_LAST_NAME = 'registrationReducer/SET_SELECTED_LAST_NAME';
const SET_SELECTED_EMAIL = 'registrationReducer/SET_SELECTED_EMAIL';
const SET_SELECTED_COMMENT = 'registrationReducer/SET_SELECTED_COMMENT';
const SET_SELECTED_PHONE = 'registrationReducer/SET_SELECTED_PHONE';
const SET_SELECTED_AVAILABLE_DATE = 'registrationReducer/SET_SELECTED_AVAILABLE_DATE';
const SET_CHOICES_TIME = 'registrationReducer/SET_CHOICES_TIME';
const SET_SELECTED_DESIRED_DATE = 'registrationReducer/SET_SELECTED_DESIRED_DATE';
const SET_SELECTED_DESIRED_TIME = 'registrationReducer/SET_SELECTED_DESIRED_TIME';
const SET_PRIVACY_POLICY = 'registrationReducer/SET_PRIVACY_POLICY';
const SET_SELECTED_TIME_DESIRED = 'registrationReducer/SET_SELECTED_TIME_DESIRED';
const SET_DOCTOR_INFO = 'registrationReducer/SET_DOCTOR_INFO';
const SET_SELECTED_DOCTOR = 'registrationReducer/SET_SELECTED_DOCTOR';

const initialState = {
  selectedBranch: null,
  selectedInsuranceType: '',
  selectedReason: '',
  selectedDate: '',
  selectedAvailableDate: '',
  desired_date: false,
  choicesTime: [{ value: '', label: '' }],
  selectedDesiredTime: { value: '', label: '' },
  selectedTimeDesiredDate: { value: '', label: '' },
  selectedDoctor: null as DoctorType | null,

  selectedGender: { value: '', label: '' },
  selectedBirthDate: '',
  selectedFirstName: '',
  selectedLastName: '',
  selectedEmail: '',
  selectedComment: '',
  selectedPhone: '',
  isPrivacyPolicy: false,

  doctors: [],

  branchInfo: {
    official: [
      {
        branches: [],
        date: '',
        end_time: '',
        id: 0,
        name: '',
        official: false,
        owner: 0,
        owner_display: '',
        start_time: '',
      } as HolidayType,
    ],
    private: [
      {
        branches: [],
        date: '',
        end_time: '',
        id: 0,
        name: '',
        official: false,
        owner: 0,
        owner_display: '',
        start_time: '',
      } as HolidayType,
    ],
    reasons: [{} as ReasonsInfoType],
    workschedule: [{} as WorkScheduleInfoType] as Array<WorkScheduleInfoType>,
    desired_date: false,
    email: '',
    free_date: false,
    name: '',
    phone: '',
    show_title: false,
    sity: '',
    street: '',
    street_number: '',
    whatsapp: '',
    zip_code: '',
    no_free_date_text: ''
  } as DateInfoOfClientType,
  chosenDayInSchedule: { date: '', time: '' },
  clientsData: {} as DataOfClientsType,
};

export type InitialStateType = {
  selectedBranch: number | null;
  selectedInsuranceType?: string;
  selectedReason?: string;
  selectedDate?: string;
  selectedAvailableDate: string;
  desired_date: boolean;
  choicesTime: Array<{ value: string; label: string }>;
  selectedDesiredTime: { value: string; label: string };
  selectedTimeDesiredDate: { value: string; label: string };
  selectedDoctor: DoctorType | null;

  selectedGender: { value: any; label: any };
  selectedBirthDate: string;
  selectedFirstName: string;
  selectedLastName: string;
  selectedEmail: string;
  selectedComment: string;
  selectedPhone: string;
  isPrivacyPolicy: boolean;

  doctors: Array<DoctorType>;

  branchInfo: DateInfoOfClientType;
  chosenDayInSchedule: ChosenDayInScheduleType;
  clientsData: DataOfClientsType;
};

export const registrationReducer = (
  state: InitialStateType = initialState,
  action: ActionsType,
): InitialStateType => {
  switch (action.type) {
    case GET_DATE_REGISTRATION:
      return { ...state, clientsData: action.data };

    case GET_BRANCH_INFO:
      return { ...state, branchInfo: action.data };

    case SET_DATA_STEP1:
      return {
        ...state,
        selectedBranch: action.payload.branchId,
        selectedDate: action.payload.selectedDate,
      };

    case SET_CHOSEN_DAY_SCHEDULE: {
      return { ...state, chosenDayInSchedule: action.day };
    }

    case SET_DESIRED_DATE: {
      return { ...state, desired_date: action.desired_date };
    }

    case SET_SELECTED_INSURANCE: {
      return { ...state, selectedInsuranceType: action.insurance_type };
    }

    case SET_SELECTED_REASON: {
      return { ...state, selectedReason: action.reason };
    }

    //
    case SET_SELECTED_GENDER: {
      return { ...state, selectedGender: action.gender.value };
    }
    case SET_SELECTED_BIRTH_DATE: {
      return { ...state, selectedBirthDate: action.birth_date };
    }
    case SET_SELECTED_FIRST_NAME: {
      return { ...state, selectedFirstName: action.first_name };
    }
    case SET_SELECTED_LAST_NAME: {
      return { ...state, selectedLastName: action.last_name };
    }
    case SET_SELECTED_EMAIL: {
      return { ...state, selectedEmail: action.email };
    }
    case SET_SELECTED_COMMENT: {
      return { ...state, selectedComment: action.comment };
    }
    case SET_SELECTED_PHONE: {
      return { ...state, selectedPhone: action.phone };
    }
    case SET_SELECTED_AVAILABLE_DATE: {
      return { ...state, selectedAvailableDate: action.date };
    }
    case SET_CHOICES_TIME: {
      return { ...state, choicesTime: action.choices };
    }
    case SET_SELECTED_DESIRED_DATE: {
      return { ...state, selectedDate: action.date };
    }
    case SET_SELECTED_DESIRED_TIME: {
      return { ...state, selectedDesiredTime: action.time };
    }
    case SET_PRIVACY_POLICY: {
      return { ...state, isPrivacyPolicy: action.privacyPolicy };
    }
    case SET_SELECTED_TIME_DESIRED: {
      return { ...state, selectedTimeDesiredDate: action.time };
    }
    case SET_DOCTOR_INFO: {
      return { ...state, doctors: action.doctors };
    }
    case SET_SELECTED_DOCTOR: {
      return { ...state, selectedDoctor: action.doctor };
    }

    default:
      return state;
  }
};

// actions
export const getDateRegistration = (data: DataOfClientsType) =>
  ({ type: GET_DATE_REGISTRATION, data } as const);

export const setDataStep1 = (branchId: number | null, selectedDate?: string) =>
  ({
    type: SET_DATA_STEP1,
    payload: {
      branchId,
      selectedDate,
    },
  } as const);

export const setChosenDayInScheduleAC = (day: any) =>
  ({ type: SET_CHOSEN_DAY_SCHEDULE, day } as const);

export const getBranchInfoAC = (data: DateInfoOfClientType) =>
  ({ type: GET_BRANCH_INFO, data } as const);

export const setDesiredDateAC = (desired_date: boolean) =>
  ({ type: SET_DESIRED_DATE, desired_date } as const);

export const setSelectedInsuranceTypeAC = (insurance_type: string) =>
  ({ type: SET_SELECTED_INSURANCE, insurance_type } as const);

export const setSelectedReasonTypeAC = (reason: string) =>
  ({ type: SET_SELECTED_REASON, reason } as const);

//
export const setSelectedGenderAC = (gender: { value: any; label: any }) =>
  ({ type: SET_SELECTED_GENDER, gender } as const);

export const setSelectedBirthDateAC = (birth_date: string) =>
  ({ type: SET_SELECTED_BIRTH_DATE, birth_date } as const);

export const setSelectedFirstNameAC = (first_name: string) =>
  ({ type: SET_SELECTED_FIRST_NAME, first_name } as const);

export const setSelectedLastNameAC = (last_name: string) =>
  ({ type: SET_SELECTED_LAST_NAME, last_name } as const);

export const setSelectedEmailAC = (email: string) => ({ type: SET_SELECTED_EMAIL, email } as const);

export const setSelectedCommentAC = (comment: string) =>
  ({ type: SET_SELECTED_COMMENT, comment } as const);

export const setSelectedPhoneAC = (phone: string) => ({ type: SET_SELECTED_PHONE, phone } as const);

export const setSelectedAvailableDateAC = (date: string) =>
  ({ type: SET_SELECTED_AVAILABLE_DATE, date } as const);

export const setChoicesTimeAC = (choices: Array<{ value: string; label: string }>) =>
  ({ type: SET_CHOICES_TIME, choices } as const);

export const setSelectedDesiredDateAC = (date: string) =>
  ({ type: SET_SELECTED_DESIRED_DATE, date } as const);

export const setSelectedDesiredTimeAC = (time: { value: string; label: string }) =>
  ({ type: SET_SELECTED_DESIRED_TIME, time } as const);

export const setPrivacyPolicyAC = (privacyPolicy: boolean) =>
  ({ type: SET_PRIVACY_POLICY, privacyPolicy } as const);

export const setSelectedTimeDesiredDateAC = (time: { value: string; label: string }) =>
  ({ type: SET_SELECTED_TIME_DESIRED, time } as const);

export const setDoctorInfoAC = (doctors: Array<DoctorType>) =>
  ({ type: SET_DOCTOR_INFO, doctors } as const);

export const setSelectedDoctorAC = (doctor: DoctorType | null) =>
  ({ type: SET_SELECTED_DOCTOR, doctor } as const);

// thunks
export const getDateRegistrationTC =
  (branchId: number | null, reasonId: number, doctorId: number | undefined, week: number) =>
  async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC('loading'));
    try {
      const res = await registrationAPI.getClientWeek(
        branchId,
        reasonId,
        doctorId,
        week,
      );
      dispatch(getDateRegistration(res.data));
      dispatch(setAppStatusAC('succeeded'));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
    dispatch(setAppStatusAC('idle'));
  };

export const getDateRegistrationFirstRenderTC =
  (branchId: number | null, doctorId: number) =>
  async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const reasonId = Number(getState().registration.selectedReason);
    try {
      const res = await registrationAPI.getClientWeekFirstRender(
        branchId,
        reasonId,
        doctorId,
      );
      dispatch(getDateRegistration(res.data));
      dispatch(setDataStep1(branchId, undefined));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
  };

export const createClientEventTC =
  (data: any, branchId: number | null) => async (dispatch: Dispatch<any>) => {
    try {
      await registrationAPI.createClientEvent(data, branchId);
      dispatch(setAppStatusAC('succeeded'));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
  };

export const getBranchInfoTC = (branchId: number | null) => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  try {
    const res = await registrationAPI.getClientBranchInfo(branchId);
    dispatch(getBranchInfoAC(res.data));
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
  dispatch(setAppStatusAC('idle'));
};

export const getDoctorInfoTC =
  (branchId: number | null, reasonId: number) =>
  async (dispatch: Dispatch<any>) => {
    try {
      const res = await registrationAPI.getDoctorInfo(branchId, reasonId);
      await dispatch(setDoctorInfoAC(res.data.doctors));

      dispatch(setSelectedDoctorAC(null));
      dispatch(setSelectedDesiredDateAC(''));
      dispatch(setSelectedAvailableDateAC(''));

      dispatch(setAppStatusAC('succeeded'));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
  };

//types
type ActionsType =
  | ReturnType<typeof getDateRegistration>
  | ReturnType<typeof setDataStep1>
  | ReturnType<typeof setChosenDayInScheduleAC>
  | ReturnType<typeof getBranchInfoAC>
  | ReturnType<typeof setDesiredDateAC>
  | ReturnType<typeof setSelectedInsuranceTypeAC>
  | ReturnType<typeof setSelectedReasonTypeAC>
  | ReturnType<typeof setSelectedGenderAC>
  | ReturnType<typeof setSelectedBirthDateAC>
  | ReturnType<typeof setSelectedFirstNameAC>
  | ReturnType<typeof setSelectedLastNameAC>
  | ReturnType<typeof setSelectedEmailAC>
  | ReturnType<typeof setSelectedCommentAC>
  | ReturnType<typeof setSelectedPhoneAC>
  | ReturnType<typeof setSelectedAvailableDateAC>
  | ReturnType<typeof setChoicesTimeAC>
  | ReturnType<typeof setSelectedDesiredDateAC>
  | ReturnType<typeof setSelectedDesiredTimeAC>
  | ReturnType<typeof setPrivacyPolicyAC>
  | ReturnType<typeof setSelectedTimeDesiredDateAC>
  | ReturnType<typeof setDoctorInfoAC>
  | ReturnType<typeof setSelectedDoctorAC>;

export type ChosenDayInScheduleType = {
  date: string;
  time: string;
};
